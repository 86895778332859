<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <OForm :class="{submitting}" @submit="submit">
    <OFormInput
      id="newEmail"
      name="newEmail"
      type="email"
      v-model="form.newEmail"
      autocomplete="email"
      required
      placeholder="Enter your new email"
    >
      New email
    </OFormInput>
    <OFormError :message="error"></OFormError>
    <FormButtons>
      <OFormSubmitButton :disabled="submitting">
        Change email
      </OFormSubmitButton>
    </FormButtons>
  </OForm>
</template>

<script>
import apiForm from '@/mixins/apiForm'
import OForm from '@/objects/forms/OForm'
import OFormSubmitButton from '@/objects/forms/OFormSubmitButton'
import FormButtons from '@/objects/forms/FormButtons'
import OFormError from '@/objects/forms/OFormError'
import OFormInput from '@/objects/forms/OFormInput'

export default {
  name: 'CFormChangeEmail',
  components: { OFormError, OFormSubmitButton, OForm, FormButtons, OFormInput },
  mixins: [apiForm],
  data: function () {
    return {
      form: {
        email: this.$store.getters.getEmail,
        password: this.$store.getters.getPassword,
        newEmail: null
      }
    }
  },
  methods: {
    submit () {
      this.$callApi(api => api.changeEmail(this.form))
    }
  }
}
</script>
