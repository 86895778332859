<template>
  <LDefault>
    <OTitle>Change email</OTitle>
    <CFormChangeEmail @success="onSuccess"></CFormChangeEmail>
  </LDefault>
</template>

<script>
import LDefault from '@/layouts/LDefault'
import CFormChangeEmail from '@/forms/CFormChangeEmail'
import OTitle from '../../objects/OTitle'

export default {
  name: 'PageChangeEmail',
  components: { OTitle, LDefault, CFormChangeEmail },
  methods: {
    onSuccess ({ message }) {
      this.$router.push({ name: 'verify' })
    }
  }
}
</script>
